import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isButtonsTextShown } from '../../../../../shared/selectors/app-settings';
import { purchaseButtonText } from '../../ui-selectors/buttons';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './purchase-button.styl';
import classNames from 'classnames';
var PurchaseButton = /** @class */ (function (_super) {
    __extends(PurchaseButton, _super);
    function PurchaseButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PurchaseButton.prototype.render = function () {
        var _a = this.props, text = _a.text, onClick = _a.onClick, iconClassName = _a.iconClassName, className = _a.className, isTextShown = _a.isTextShown, videoItem = _a.videoItem;
        var currency = videoItem && videoItem.dealInfo[0] && videoItem.dealInfo[0].currency;
        return text ? (React.createElement("div", { "data-hook": "paid-access-button", className: className, onClick: onClick },
            React.createElement("div", { className: classNames(styles.purchaseIcon, iconClassName) },
                React.createElement(CurrencySign, { currency: currency })),
            isTextShown && text)) : null;
    };
    PurchaseButton.propTypes = {
        videoItem: PropTypes.object.isRequired,
        text: PropTypes.object,
        iconClassName: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        isTextShown: PropTypes.bool,
        className: PropTypes.string,
    };
    PurchaseButton = __decorate([
        connect(function (state, props) { return ({
            text: purchaseButtonText(state, props),
            isTextShown: isButtonsTextShown(state),
        }); }),
        withStyles(styles)
    ], PurchaseButton);
    return PurchaseButton;
}(React.Component));
export default PurchaseButton;
