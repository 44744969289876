import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import ActionsStrip from '../../actions-strip/actions-strip';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from './compact.styl';
import CoverWithOverlay from '../../cover-with-overlay/cover-with-overlay';
import SlidableListWithLazyLoad from '../../slidable-list-with-lazy-load';
import SlideMenu from '../../slide-menu/slide-menu';
import SlideMenuButton from '../../buttons/slide-menu-button';
import SubscribeButton from '../../buttons/subscribe-button';
import NavigationDots from '../../navigation-dots/navigation-dots';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import AutoPlayVideo from '../../../../../../shared/components/autoplay-video/autoplay-video';
import { showAutoPlay } from '../../../../../../widget/selectors/layout';
import { isRTL } from '../../../../../../shared/selectors/app-settings';
import { PictureMode } from '@wix/wix-vod-shared/dist/src/common/components/picture';
var Compact = /** @class */ (function (_super) {
    __extends(Compact, _super);
    function Compact(props) {
        var _this = _super.call(this, props) || this;
        _this.showCover = function () {
            _this.setState({
                isMainCoverOverlayOn: true,
            });
        };
        _this.closeCover = function () {
            _this.setState({
                isMainCoverOverlayOn: false,
            });
        };
        _this.beforeCurrentSlideChanged = function (oldIndex, newIndex) {
            _this.setState({
                selectedIndex: newIndex,
            });
        };
        _this.onCurrentSlideChanged = function (newIndex) {
            _this.props.onCurrentSlideChanged(newIndex);
        };
        _this.onPlayRequestedAllowed = function () {
            var _a = _this.props, mainVideo = _a.mainVideo, playVideo = _a.playVideo;
            playVideo(mainVideo);
            _this.closeCover();
        };
        _this.onPlayEnded = function () {
            if (!_this.props.isVideoPlaying) {
                _this.showCover();
            }
        };
        _this.onClosePlayerClick = function () {
            var _a = _this.props, mainVideo = _a.mainVideo, pauseVideo = _a.pauseVideo;
            pauseVideo(mainVideo.id);
            _this.showCover();
        };
        _this.state = {
            PlaybackComponent: null,
            isMainCoverOverlayOn: true,
            selectedIndex: _.indexOf(props.videoIds, props.mainVideoId, 0),
        };
        return _this;
    }
    Compact.prototype.componentDidMount = function () {
        var _this = this;
        this.props
            .loadPlaybackModule()
            .then(function (PlaybackComponent) {
            _this.setState({
                PlaybackComponent: PlaybackComponent,
            });
        })
            .catch(_.noop);
    };
    Compact.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var isVideoPlaying = nextProps.isVideoPlaying;
        if (this.props.isVideoPlaying !== isVideoPlaying && isVideoPlaying) {
            this.closeCover();
        }
    };
    Compact.prototype.renderSubscribeButton = function (videoItem) {
        var onSubscribeClick = this.props.onSubscribeClick;
        return (React.createElement(SubscribeButton, { className: styles.subscribe, onClick: onSubscribeClick, videoItem: videoItem }));
    };
    Compact.prototype.renderTopMenuArea = function (videoItem, isFirstVideo) {
        var channelData = this.props.channelData;
        return (React.createElement("div", { "data-hook": "top-menu-area", className: styles['top-menu-area'] },
            this.renderSubscribeButton(videoItem),
            React.createElement("div", { className: styles['slide-menu-icon-wrapper'] },
                React.createElement(SlideMenuButton, { className: styles['slide-menu-icon-wrapper'], iconClassName: styles['slide-menu-icon'], videoItem: videoItem, channelData: channelData, isFirstVideo: isFirstVideo }))));
    };
    Compact.prototype.renderNoVideosCover = function () {
        var _a = this.props, channelData = _a.channelData, _b = _a.overlaySize, width = _b.width, height = _b.height;
        return (React.createElement(CoverWithOverlay, { channelData: channelData, showChannelCover: true, itemWidth: width, itemHeight: height, onSignInRequested: _.noop, className: styles['overlay-only'], coverUrl: getChannelCoverUrl(channelData) }));
    };
    Compact.prototype.renderCover = function (videoItem, isFirstVideo, className) {
        if (className === void 0) { className = null; }
        var _a = this.props, _b = _a.overlaySize, width = _b.width, height = _b.height, onPlayRequestedDisallowed = _a.onPlayRequestedDisallowed, showLogInForm = _a.showLogInForm;
        return (React.createElement(React.Fragment, null,
            this.renderTopMenuArea(videoItem, isFirstVideo),
            React.createElement(CoverWithOverlay, { videoItem: videoItem, className: className, itemWidth: width, itemHeight: height, isFirstVideo: isFirstVideo, onSignInRequested: showLogInForm, onPlayRequestedDisallowed: onPlayRequestedDisallowed, onPlayRequestedAllowed: this.onPlayRequestedAllowed, onMemberSignUp: memoizedPartial(this.props.openMemberOnlyPage, videoItem.id), pictureMode: PictureMode.CONTAIN })));
    };
    Compact.prototype.renderCoverCarousel = function () {
        var _this = this;
        var _a = this.props, videoIds = _a.videoIds, videoByIds = _a.videoByIds;
        return _(videoIds)
            .filter(function (videoId) { return Boolean(videoByIds[videoId]); })
            .map(function (videoId, index) { return (React.createElement("div", { key: videoId, role: "presentation" }, _this.renderCover(videoByIds[videoId], index === 0, styles['overlay-item-topmost']))); })
            .value();
    };
    Compact.prototype.renderSlideMenu = function (videoItem, isFirstVideo) {
        var _a = this.props, isSlideMenuOpened = _a.isSlideMenuOpened, channelData = _a.channelData;
        if (!isSlideMenuOpened || !videoItem) {
            return null;
        }
        return (React.createElement(SlideMenu, { videoItem: videoItem, channelData: channelData, onMemberSignUp: memoizedPartial(this.props.openMemberOnlyPage, videoItem.id), isFirstVideo: isFirstVideo }));
    };
    Compact.prototype.renderNavigationDots = function () {
        var _a = this.props, videoIds = _a.videoIds, isRTL = _a.isRTL;
        return (React.createElement("div", { className: styles['navigation-dots'] },
            React.createElement(NavigationDots, { count: _.size(videoIds), selectedIndex: this.state.selectedIndex || 0, isRTL: isRTL })));
    };
    Compact.prototype.renderMultipleVideosOverlay = function () {
        var height = this.props.overlaySize.height;
        var _a = this.props, mainVideoId = _a.mainVideoId, videoIds = _a.videoIds, videoByIds = _a.videoByIds;
        var isFirstVideo = _.startsWith(videoIds, mainVideoId);
        return (React.createElement("div", null,
            React.createElement(SlidableListWithLazyLoad, { itemHeight: height, className: styles['players-list-slidable'], onCurrentSlideChanged: this.onCurrentSlideChanged, beforeCurrentSlideChanged: this.beforeCurrentSlideChanged, initialVideoId: this.props.mainVideoId }, this.renderCoverCarousel()),
            this.renderNavigationDots(),
            this.renderSlideMenu(videoByIds[mainVideoId], isFirstVideo)));
    };
    Compact.prototype.renderSingleVideoOverlay = function () {
        var mainVideo = this.props.mainVideo;
        // NOTE: single-video-slick-active class is used for test purposes only
        var className = styles['single-item-cover'] + " single-video-slick-active";
        return (React.createElement("div", { className: className, role: "presentation" },
            this.renderCover(mainVideo, true),
            this.renderSlideMenu(mainVideo)));
    };
    Compact.prototype.renderMainCoverOverlay = function () {
        var _a;
        var isSingleVideoView = this.props.isSingleVideoView;
        var isMainCoverOverlayOn = this.state.isMainCoverOverlayOn;
        var className = classnames(styles['main-cover-overlay'], (_a = {},
            _a[styles.visible] = isMainCoverOverlayOn,
            _a));
        return (React.createElement("div", { "data-hook": "main-cover-overlay", className: className }, isSingleVideoView
            ? this.renderSingleVideoOverlay()
            : this.renderMultipleVideosOverlay()));
    };
    // TODO: make it not dirty
    Compact.prototype.renderPlayer = function () {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, mainVideo = _a.mainVideo, channelData = _a.channelData, openMemberOnlyPage = _a.openMemberOnlyPage, _b = _a.playerSize, width = _b.width, height = _b.height;
        var PlaybackComponent = this.state.PlaybackComponent;
        if (!PlaybackComponent) {
            return null;
        }
        return (React.createElement(PlaybackComponent, { videoItem: mainVideo, channelData: channelData, paused: !isVideoPlaying, onEnded: this.onPlayEnded, width: width, height: height, onMemberSignUp: openMemberOnlyPage }));
    };
    Compact.prototype.renderPlayerWithActions = function () {
        var _a;
        var _b = this.props, mainVideo = _b.mainVideo, channelData = _b.channelData, showAutoPlay = _b.showAutoPlay;
        var isMainCoverOverlayOn = this.state.isMainCoverOverlayOn;
        var styleName = classnames(styles['player-with-actions'], (_a = {},
            _a[styles['cover-on']] = isMainCoverOverlayOn,
            _a));
        return (React.createElement("div", { "data-hook": "player-with-actions", className: styleName },
            !this.state.isMainCoverOverlayOn && (React.createElement(ActionsStrip, { videoItem: mainVideo, channelData: channelData, className: styles['action-strip'], onCloseClick: this.onClosePlayerClick })),
            React.createElement("div", { className: styles.player },
                this.renderPlayer(),
                showAutoPlay && React.createElement(AutoPlayVideo, { isMobile: true }))));
    };
    Compact.prototype.render = function () {
        var _a;
        var _b = this.props, mainVideo = _b.mainVideo, _c = _b.overlaySize, width = _c.width, height = _c.height;
        var isMainCoverOverlayOn = this.state.isMainCoverOverlayOn;
        if (!mainVideo) {
            return this.renderNoVideosCover();
        }
        var styleName = classnames(styles['main-ui'], (_a = {},
            _a[styles['cover-on']] = isMainCoverOverlayOn,
            _a));
        return (React.createElement("div", { "data-hook": "main-ui", className: styleName, style: { width: width, height: height } },
            this.renderMainCoverOverlay(),
            this.renderPlayerWithActions()));
    };
    Compact.propTypes = {
        overlaySize: PropTypes.object.isRequired,
        playerSize: PropTypes.object.isRequired,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        mainVideo: PropTypes.object,
        selectVideo: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        openVideoPage: PropTypes.func.isRequired,
        showLogInForm: PropTypes.func.isRequired,
        onCurrentSlideChanged: PropTypes.func.isRequired,
        openMemberOnlyPage: PropTypes.func.isRequired,
        onSubscribeClick: PropTypes.func,
        onPlayRequestedDisallowed: PropTypes.func.isRequired,
        loadPlaybackModule: PropTypes.func.isRequired,
        showAutoPlay: PropTypes.bool,
        isMainItemChannelCover: PropTypes.bool,
        isVideoPlaying: PropTypes.bool.isRequired,
        isSingleVideoView: PropTypes.bool,
        isSlideMenuOpened: PropTypes.bool,
        isRTL: PropTypes.bool,
        initialVideoId: PropTypes.string,
        mainVideoId: PropTypes.string,
        videoIds: PropTypes.array,
    };
    Compact.defaultProps = {
        onSubscribeClick: _.noop,
    };
    Compact = __decorate([
        connect(function (state) { return ({
            isSlideMenuOpened: state.slideMenuOpened,
            showAutoPlay: showAutoPlay(state),
            windowSize: state.windowSize,
            isRTL: isRTL(state),
        }); }),
        withStyles(styles)
    ], Compact);
    return Compact;
}(React.Component));
export default Compact;
