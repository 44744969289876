import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import pubsub from '../../utils/pubsub';
import invertSide from '../../utils/popout/invert-side';
import { isPortableDevice } from '../../selectors/form-factor';
import PropTypes from 'prop-types';
import events from '../../constants/events';
import { sides, popoutPositions, trianglePositions, } from '../../constants/popout';
import { sideShape, popoutPositionShape, trianglePositionShape, } from '../../shapes/popout';
import { withStyles } from '../../utils/withStyles';
import styles from './popout.styl';
var _propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    innerClassName: PropTypes.string,
    children: PropTypes.any,
    popoutSide: sideShape,
    popoutPosition: popoutPositionShape,
    trianglePosition: trianglePositionShape,
    triangleClassName: PropTypes.string,
    getRef: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClickOutside: PropTypes.func,
    height: PropTypes.number,
    isActive: PropTypes.bool,
};
var _defaultProps = {
    popoutSide: sides.TOP,
    popoutPosition: popoutPositions.CENTER,
    trianglePosition: trianglePositions.CENTER,
    getRef: _.noop,
    onMouseEnter: _.noop,
    onMouseLeave: _.noop,
    onClickOutside: _.noop,
};
export function showPopout(popout) {
    pubsub.publish(events.POPOUT.SHOW, popout);
}
var PopoutBase = /** @class */ (function (_super) {
    __extends(PopoutBase, _super);
    function PopoutBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickOutside = function (event) {
            var target = event.target;
            if (target !== window && _this.popout && _this.popout.contains(target)) {
                return;
            }
            _this.props.onClickOutside({ event: event });
        };
        _this.hideAllButOnePopout = function (popoutToShow) {
            if (popoutToShow && _this.popout && !_this.popout.contains(popoutToShow)) {
                _this.props.onClickOutside();
            }
        };
        _this.getRef = function (node) {
            _this.popout = node;
            _this.props.getRef(node);
        };
        return _this;
    }
    PopoutBase.prototype.componentDidMount = function () {
        window.addEventListener('blur', this.handleClickOutside);
        if (this.props.isPortableDevice) {
            document.addEventListener('touchstart', this.handleClickOutside);
        }
        else {
            document.addEventListener('click', this.handleClickOutside);
        }
        this.unsubscribeHide = pubsub.subscribe(events.POPOUT.SHOW, this.hideAllButOnePopout);
    };
    PopoutBase.prototype.componentWillUnmount = function () {
        window.removeEventListener('blur', this.handleClickOutside);
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
        if (this.unsubscribeHide) {
            this.unsubscribeHide();
        }
    };
    PopoutBase.prototype.render = function () {
        var _a = this.props, children = _a.children, className = _a.className, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, triangleClassName = _a.triangleClassName, height = _a.height, isActive = _a.isActive, popoutSide = _a.popoutSide, trianglePosition = _a.trianglePosition;
        var style = this.props.style;
        if (height) {
            style = __assign(__assign({}, style), { height: height });
        }
        var classNames = classnames(className, styles.content, [styles["triangle-" + invertSide(popoutSide)]], [styles["triangle-" + trianglePosition]]);
        return (React.createElement("div", { ref: this.getRef, className: classNames, style: style, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
            React.createElement("div", { className: classnames(triangleClassName, styles.triangle, styles.before) }),
            React.createElement("div", { className: styles['tooltip-content'], "data-hook": isActive ? 'tooltip-content' : '' }, children),
            React.createElement("div", { className: classnames(triangleClassName, styles.triangle, styles.after) })));
    };
    PopoutBase.propTypes = _propTypes;
    PopoutBase.defaultProps = _defaultProps;
    PopoutBase = __decorate([
        connect(function (state) { return ({ isPortableDevice: isPortableDevice(state) }); }),
        withStyles(styles)
    ], PopoutBase);
    return PopoutBase;
}(React.Component));
var Popout = /** @class */ (function (_super) {
    __extends(Popout, _super);
    function Popout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Popout.prototype.render = function () {
        var _a = this.props, innerClassName = _a.innerClassName, children = _a.children;
        var props = _.omit(this.props, 'styles', 'innerClassName', 'children');
        return (React.createElement(PopoutBase, __assign({}, props),
            React.createElement("div", { className: classnames(styles.inner, innerClassName) }, children)));
    };
    Popout.propTypes = _propTypes;
    Popout.defaultProps = _defaultProps;
    return Popout;
}(React.Component));
export default Popout;
