import { __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logBi, logWidgetSystem } from '../../worker/actions/bi';
import { requestLogin, logOutCurrentMember } from '../../utils/auth';
import { openFullScreenOverlay, openFullScreenChannelOverlay, openFullScreenSubscribeOverlay, openFullScreenShareOverlay, } from '../../utils/open-overlay';
import { notForPreview } from '../../utils/not-for-preview';
import { getSiteUrl, getInstance, } from '../../../widget/redux/client/hydrated-data/hydrated-data';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import { publicApi } from '../../../api/public';
var ChannelActionsContainer = /** @class */ (function (_super) {
    __extends(ChannelActionsContainer, _super);
    function ChannelActionsContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showChannelInfoAtOverlay = function () {
            _this.props.openFullScreenChannelOverlay(_this.props.channelId);
        };
        _this.showChannelShareAtOverlay = notForPreview(function () {
            var _a = _this.props, videoId = _a.videoId, channelId = _a.channelId;
            _this.props.openFullScreenShareOverlay(channelId, videoId);
        });
        _this.openAccountInfoAtOverlay = notForPreview(function () {
            _this.props.openFullScreenOverlay("#/account-info/" + _this.props.channelId);
        });
        _this.showChannelSubscriptionInfoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, isPricingPlan = _a.isPricingPlan;
            _this.props.logBi('widget.subscription.clicked', { channelID: channelId });
            _this.props.openFullScreenSubscribeOverlay(channelId, function () {
                _this.props.logBi('widget.subscriptionPopUp.Completed', {
                    result: 'canceled',
                    errorMsg: '',
                });
            }, isPricingPlan);
        });
        _this.cancelChannelSubscription = notForPreview(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteUrl, instance, channelId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, siteUrl = _a.siteUrl, instance = _a.instance, channelId = _a.channelId;
                        this.props.logWidgetSystem('channel.unsubscribe.requested');
                        return [4 /*yield*/, publicApi.purchase.cancelOrder(channelId, siteUrl, instance)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        _this.showLoginForm = notForPreview(function () {
            _this.props.logBi('widget.signIn.clicked', {
                channelID: _this.props.channelId,
            });
            _this.props.requestLogin().then(_this.props.onPageRefresh);
        });
        _this.logOut = function () {
            _this.props.logOutCurrentMember();
            _this.props.onPageRefresh();
        };
        return _this;
    }
    ChannelActionsContainer.prototype.render = function () {
        return this.props.children({
            showInfo: this.showChannelInfoAtOverlay,
            showAccountInfo: this.openAccountInfoAtOverlay,
            subscribe: this.showChannelSubscriptionInfoAtOverlay,
            cancelSubscription: this.cancelChannelSubscription,
            logOut: this.logOut,
            logIn: this.showLoginForm,
            showShare: this.showChannelShareAtOverlay,
        });
    };
    ChannelActionsContainer.propTypes = {
        children: PropTypes.func.isRequired,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        siteUrl: PropTypes.string,
        instance: PropTypes.string,
        onPageRefresh: PropTypes.func.isRequired,
    };
    ChannelActionsContainer.defaultProps = {
        onPageRefresh: _.noop,
    };
    ChannelActionsContainer = __decorate([
        connect(function (state) { return ({
            siteUrl: getSiteUrl(state),
            instance: getInstance(state),
            isPricingPlan: isPricingPlanEnabled(state.channelInfo.info),
        }); }, {
            logBi: logBi,
            logWidgetSystem: logWidgetSystem,
            requestLogin: requestLogin,
            logOutCurrentMember: logOutCurrentMember,
            openFullScreenShareOverlay: openFullScreenShareOverlay,
            openFullScreenChannelOverlay: openFullScreenChannelOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenOverlay: openFullScreenOverlay,
        })
    ], ChannelActionsContainer);
    return ChannelActionsContainer;
}(React.Component));
export default ChannelActionsContainer;
