import React from 'react';
import PropTypes from 'prop-types';
import ResizedImage from '../../../../../shared/components/resized-image/resized-image';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './no-videos-content.styl';
var NoVideosContent = function (_a) {
    var children = _a.children, width = _a.width, height = _a.height, isMainItemChannelCover = _a.isMainItemChannelCover, channelCoverUrl = _a.channelCoverUrl;
    if (isMainItemChannelCover) {
        return (React.createElement(ResizedImage, { className: styles['overlay-only'], src: channelCoverUrl, width: width, height: height }, children));
    }
    return (React.createElement("div", { className: styles['no-videos'], style: { width: width, height: height }, "data-hook": "noPlayer" }, children));
};
NoVideosContent.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,
    isMainItemChannelCover: PropTypes.bool,
    channelCoverUrl: PropTypes.string,
};
NoVideosContent.defaultProps = {
    children: React.createElement("div", null),
    width: '100%',
    height: '100%',
};
export default withStyles(styles)(NoVideosContent);
