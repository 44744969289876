import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTitleText, getSubscribeButtonText, getPublisherText, getPlayButtonText, getPurchaseButtonText, getPlayNextButtonText, getOpacityStyle, } from '../selectors';
import { openSubscription } from '../../../redux/client/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from '../../../redux/client/player-overlay/actions/open-player-overlay';
import { openPurchase } from '../../../redux/client/player-overlay/actions/open-purchase';
import { playSelectedVideo } from '../../../redux/client/player-overlay/actions/play-selected-video';
import ComingSoon from '../partials/coming-soon';
import Button from '../../button/button';
import LiveLabel from '../../../containers/live-label/live-label';
import Icon from '../../../../shared/components/icon/icon';
import { hasVideos } from '../../../../shared/utils/channel-helpers';
import Title from '../partials/title';
import classnames from 'classnames';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './classic.styl';
var ClassicPlayerOverlay = /** @class */ (function (_super) {
    __extends(ClassicPlayerOverlay, _super);
    function ClassicPlayerOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePlayNextVideoClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            _this.props.playNextVideo();
        };
        _this.handleSubscriptionButtonClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            _this.props.openSubscription();
        };
        _this.handlePurchaseButtonClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            var _a = _this.props, openPlayerOverlay = _a.openPlayerOverlay, videoItem = _a.videoItem;
            openPlayerOverlay(videoItem);
        };
        _this.handlePlaySelectedVideo = function (event) {
            event.preventDefault();
            event.stopPropagation();
            var _a = _this.props, videoItem = _a.videoItem, playSelectedVideo = _a.playSelectedVideo, onPlaySelectedVideo = _a.onPlaySelectedVideo;
            playSelectedVideo({ videoItem: videoItem, onPlaySelectedVideo: onPlaySelectedVideo });
        };
        return _this;
    }
    ClassicPlayerOverlay.prototype.renderPublisher = function () {
        var publisherText = this.props.publisherText;
        var className = classnames(styles.publisher, 'qa-widget-overlay-publisher');
        return (React.createElement("div", { className: className, "data-hook": "overlay-publisher" },
            React.createElement("span", null, publisherText)));
    };
    ClassicPlayerOverlay.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, mediaInfo: videoItem.mediaInfo, videoTitle: videoItem.title, startTime: videoItem.dateStartLive, className: styles['live-label'] }));
    };
    ClassicPlayerOverlay.prototype.renderPlayButton = function () {
        var _a = this.props, channelData = _a.channelData, playButtonText = _a.playButtonText, isPlayerModuleLoaded = _a.isPlayerModuleLoaded;
        if (!playButtonText || !isPlayerModuleLoaded) {
            return null;
        }
        if (!hasVideos(channelData)) {
            return React.createElement(ComingSoon, { className: styles['soon-info'] });
        }
        return (React.createElement(Button, { dataHook: "overlay-play-button", className: styles.button, onClick: this.handlePlaySelectedVideo },
            React.createElement(Icon, { className: styles['play-icon'], name: "arrow-right-filled" }),
            React.createElement("span", null, playButtonText)));
    };
    ClassicPlayerOverlay.prototype.renderPlayNextButton = function () {
        var _a = this.props, playNextButtonText = _a.playNextButtonText, isPlayerModuleLoaded = _a.isPlayerModuleLoaded;
        if (!playNextButtonText || !isPlayerModuleLoaded) {
            return null;
        }
        return (React.createElement(Button, { className: styles.button, "data-hook": "overlay-next-video-button", onClick: this.handlePlayNextVideoClick }, playNextButtonText));
    };
    ClassicPlayerOverlay.prototype.renderSubscribeButton = function () {
        var subscribeButtonText = this.props.subscribeButtonText;
        if (!subscribeButtonText) {
            return null;
        }
        return (React.createElement(Button, { className: styles.button, dataHook: "subscribe-button", onClick: this.handleSubscriptionButtonClick }, subscribeButtonText));
    };
    ClassicPlayerOverlay.prototype.renderPurchaseButton = function () {
        var purchaseButtonText = this.props.purchaseButtonText;
        if (!purchaseButtonText) {
            return null;
        }
        return (React.createElement(Button, { className: styles.button, dataHook: "paid-access-button", onClick: this.handlePurchaseButtonClick }, purchaseButtonText));
    };
    ClassicPlayerOverlay.prototype.renderButtons = function () {
        if (!this.props.videoItem) {
            return null;
        }
        return (React.createElement("div", { className: styles.buttons },
            this.renderPlayButton(),
            this.renderSubscribeButton() || this.renderPurchaseButton(),
            this.renderPlayNextButton()));
    };
    ClassicPlayerOverlay.prototype.render = function () {
        var _a = this.props, className = _a.className, opacityStyle = _a.opacityStyle, publisherText = _a.publisherText, titleText = _a.titleText, saveRef = _a.saveRef;
        return (React.createElement("section", { className: styles['player-overlay-container'], ref: saveRef },
            React.createElement("div", { style: opacityStyle, className: classnames(className, styles.overlay), "data-hook": "player-overlay player-overlay-visible", onClick: this.handlePlaySelectedVideo },
                titleText && (React.createElement("div", { className: styles.title },
                    React.createElement(Title, { text: titleText }))),
                publisherText && this.renderPublisher(),
                this.renderLiveLabel(),
                this.renderButtons())));
    };
    ClassicPlayerOverlay.propTypes = {
        titleText: PropTypes.node,
        publisherText: PropTypes.node,
        subscribeButtonText: PropTypes.node,
        playButtonText: PropTypes.node,
        purchaseButtonText: PropTypes.node,
        playNextButtonText: PropTypes.node,
        opacityStyle: PropTypes.object.isRequired,
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        className: PropTypes.string,
        openSubscription: PropTypes.func.isRequired,
        openPurchase: PropTypes.func.isRequired,
        openPlayerOverlay: PropTypes.func.isRequired,
        playSelectedVideo: PropTypes.func.isRequired,
        onPlaySelectedVideo: PropTypes.func,
        playNextVideo: PropTypes.func,
        saveRef: PropTypes.func,
        nextVideoId: PropTypes.string,
        isPlayerModuleLoaded: PropTypes.bool,
    };
    ClassicPlayerOverlay = __decorate([
        connect(function (state, ownProps) { return ({
            titleText: getTitleText(state, ownProps),
            publisherText: getPublisherText(state, ownProps),
            subscribeButtonText: getSubscribeButtonText(state, ownProps),
            playButtonText: getPlayButtonText(state, ownProps),
            purchaseButtonText: getPurchaseButtonText(state, ownProps),
            playNextButtonText: getPlayNextButtonText(state, ownProps),
            opacityStyle: getOpacityStyle(state),
            isPlayerModuleLoaded: state.modules.player,
        }); }, {
            openSubscription: openSubscription,
            openPlayerOverlay: openPlayerOverlay,
            playSelectedVideo: playSelectedVideo,
            openPurchase: openPurchase,
        }),
        withStyles(styles)
    ], ClassicPlayerOverlay);
    return ClassicPlayerOverlay;
}(React.Component));
export default ClassicPlayerOverlay;
