import { getVideoUrls } from '@wix/wix-vod-api/dist/src/public/video/video';
import createAsyncActions from '../../../../../shared/redux/helpers/create-async-actions';
import { getInstance } from '../../hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../../../shared/worker/lib';
var ACTIONS = createAsyncActions('CLIENT.VIDEO_URLS.GET');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var fetchVideoUrls = createAsyncProxy('fetchVideoUrls', function (videoItem, force) {
    if (force === void 0) { force = false; }
    return function (dispatch, getState) {
        var state = getState();
        var videoUrlsCache = state.videoUrlsCache;
        var instance = getInstance(state);
        if (!force && (!videoItem || videoUrlsCache[videoItem.id])) {
            return;
        }
        var params = { videoItem: videoItem };
        dispatch(START(params));
        /*
         * When videoItem is a bought video response from member/info and original video is too far in list
         * and wasn't loaded yet
         * */
        if (videoItem.dgsInfo && !videoItem.itemId && !videoItem.listId) {
            return Promise.resolve();
        }
        return getVideoUrls(videoItem.itemId, videoItem.listId, instance).then(function (response) {
            dispatch(SUCCESS(params, response));
            return response;
        }, function (response) {
            dispatch(FAIL(params, response));
        });
    };
});
