import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { COMPACT_VIDEOS_IN_ROW, COMPACT_NUMBER_OF_ROWS, } from '@wix/wix-vod-constants/dist/app-settings/compact-video-counts';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import { isThumbnailsPreviewHover, canShowActionBar, canShowChannelShare, canShowVideoList, getCanShowSignIn, getCanShowChannelInfo, isPlayInFrame, isSingleVideo, isMainItemChannelCover, isRTL, } from '../../../shared/selectors/app-settings';
import { isSubscriptionButtonVisible } from '../../../shared/components/overlay-texts/channel/subscribe-button-text-utils';
import { sendBiEvent } from '../../../shared/bi/send-bi-event';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from '../../redux/client/actions/full-screen-modal';
import { playerSize } from '../../utils/videos-sizes/videos-sizes';
import { getMainVideo } from '../../selectors/get-video';
import { showAutoPlay, isAllCardsOverlayVisible } from '../../selectors/layout';
import { isVideoPlayingOptimistic, isVideoPausedOptimistic, } from '../../selectors/video-playback-status';
import { getVideosGroupedByIds } from '../../../shared/selectors/videos';
import { getCurrentSiteUser } from '../../../shared/selectors/current-site-user';
import STATS_EVENTS from '../../../shared/constants/stats/events';
import { goToLazyVideosPageIndex } from '../../redux/client/lazy-channel-videos/actions';
import { selectVideo, resetSelectedVideo, } from '../../redux/client/actions/select-video';
import { setSavedTime } from '../../redux/client/actions/player/set-saved-time';
import { playVideo, pauseVideo, resetPlaybackStatus, } from '../../redux/client/actions/player/change-playback-status';
import { requestPlayVideo } from '../../redux/client/actions/request-play-video';
import { reportStats } from '../../redux/server/actions/stats/report';
import { closeShareOverlay } from '../../redux/client/actions/share-overlay';
import { logWidgetVidClick } from '../../../shared/utils/bi/widget-common-events';
import { logWidgetSystem } from '../../../shared/worker/actions/bi';
import PaymentEvents from '../../../shared/components/payment-events/payment-events';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import ResizeWidgetByContent from './components/resize-widget-by-content/resize-widget-by-content';
import DeeplinkPopups from '../../components/deeplink-popups/deeplink-popups';
import ShareOverlay from '../../containers/share-overlay/share-overlay';
import PlayerBlocksVisibility from '../../containers/player-blocks-visibility';
import Videos from './components/videos/videos';
import ChannelOverlay from './components/player-overlay/channel-overlay';
import VideoOverlay from './components/player-overlay/video-overlay';
import ChannelActions from './components/channel-actions/channel-actions';
import ActionBar from './components/action-bar/action-bar';
import MainItemSlider from '../../components/main-item-slider/main-item-slider';
import AccessibleVideosContainer from '../../components/accessible-videos-container/accessible-videos-container';
import InFrameActionCallbacks from './actions/overlay-action-callbacks/in-frame';
import InFrameMobileActionCallbacks from './actions/overlay-action-callbacks/in-frame-mobile';
import InOverlayActionCallbacks from './actions/overlay-action-callbacks/in-overlay';
import ChannelActionsContainer from '../../../shared/containers/channel-actions/channel-actions';
import PlayerActionCallbacks from './actions/player-action-callbacks';
import AutoPlayVideo from '../../../shared/components/autoplay-video/autoplay-video';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './compact.styl';
import { getVideoIdsByPageNumber, getCurrentPageIndex, getCurrentVideoIndex, getIsFetching, getVideoIds, hasNextVideo, hasPrevVideo, } from '../../redux/client/lazy-channel-videos/selectors';
import { ComingSoon } from '../../components/coming-soon/coming-soon';
var DEFAULT_STATE = {
    areAllVideosVisible: false,
    isShareOverlayVisible: false,
};
export var MIN_WIDGET_WIDTH = 400;
export var MAX_WIDGET_WIDTH = 980;
var ACTION_BAR_OPENED_HEIGHT = 60;
var ACTION_BAR_CLOSED_HEIGHT = 40;
var CompactView = /** @class */ (function (_super) {
    __extends(CompactView, _super);
    function CompactView(props) {
        var _this = _super.call(this, props) || this;
        _this.videosContainerRef = null;
        // FIXME: playerOverlayContainerRef and shareOverlayContainerRef are not saved!
        _this.playerOverlayContainerRef = null;
        _this.shareOverlayContainerRef = null;
        _this.saveVideosContainerRef = function (ref) {
            _this.videosContainerRef = ref;
        };
        _this.sendWidgetVidClickedBi = function (videoItem) {
            var channelData = _this.props.channelData;
            _this.props.logWidgetVidClick({ videoItem: videoItem, channelData: channelData });
        };
        _this.reset = function () {
            _this.setState(DEFAULT_STATE);
            _this.props.goToLazyVideosPageIndex(0);
        };
        _this.showAllVideos = function () {
            var _a = _this.props, isVideoPlaying = _a.isVideoPlaying, pauseVideo = _a.pauseVideo, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            _this.props.sendBiEvent('widget.actionBar.open.click');
            if (isVideoPlaying) {
                pauseVideo();
            }
            _this.setState({
                areAllVideosVisible: true,
            });
            if (!currentVideosPageNumber) {
                goToLazyVideosPageIndex(0);
            }
            _this.props.logWidgetSystem('videoList.show.requested');
        };
        _this.hideAllVideos = function () {
            _this.setState({
                areAllVideosVisible: false,
            });
            _this.props.closeShareOverlay();
        };
        _this.toggleShareOverlay = function () {
            _this.setState({
                isShareOverlayVisible: !_this.state.isShareOverlayVisible,
            });
        };
        _this.hideShareOverlay = function () {
            _this.setState({
                isShareOverlayVisible: false,
            });
        };
        _this.reportChannelSharedStats = function (networkName) {
            var _a = _this.props, reportStats = _a.reportStats, channelData = _a.channelData;
            _this.props.sendBiEvent('widget.share.clicked', { typeShare: networkName });
            reportStats(STATS_EVENTS.CHANNEL_SHARE, {
                network: {
                    name: networkName,
                },
                channel: channelData,
            });
        };
        _this.renderPlayerOverlayItem = function (videoId, isVisible) {
            var _a = _this.playerSize, width = _a.width, height = _a.height;
            var _b = _this.props, channelData = _b.channelData, currentSiteUser = _b.currentSiteUser, videoByIds = _b.videoByIds, mainVideoId = _b.mainVideoId;
            var Overlay = _this.isAtChannelCover(videoId)
                ? ChannelOverlay
                : VideoOverlay;
            var videoItem = videoByIds[videoId];
            var OverlayActionsCallbacks = _this.overlayActionsCallbacks;
            return (React.createElement(OverlayActionsCallbacks, { isMainVideo: videoId === mainVideoId, onBeforeClick: _this.hideAllVideos, onBeforePlayRequested: _this.hideAllVideos, onPlayRequestedBi: _.partial(_this.sendWidgetVidClickedBi, videoItem), channelId: channelData.id, videoId: videoItem.id, videoItem: videoItem },
                React.createElement(Overlay, { currentSiteUser: currentSiteUser, width: width, height: height, isContentFocusable: isVisible, channelData: channelData, videoItem: videoItem })));
        };
        _this.renderMainItemSlider = function () {
            var _a = _this.props, lazyLoadedVideoIds = _a.lazyLoadedVideoIds, mainVideo = _a.mainVideo, mainVideoId = _a.mainVideoId, isSingleVideo = _a.isSingleVideo, isVideosFetching = _a.isVideosFetching, currentIdIndex = _a.currentIdIndex, channelData = _a.channelData;
            var loadedVideoIds = isSingleVideo ? [mainVideoId] : lazyLoadedVideoIds;
            if (!mainVideo && !isVideosFetching) {
                return React.createElement(ComingSoon, { coverUrl: getChannelCoverUrl(channelData) });
            }
            if (!mainVideoId) {
                return null;
            }
            if (currentIdIndex === -1) {
                return _this.renderPlayerOverlayItem(mainVideoId, true);
            }
            return (React.createElement(MainItemSlider, { className: styles['main-video-slider'], loadedVideoIds: loadedVideoIds, mainVideoId: mainVideoId, isMainVideoAtChannelCover: _this.isAtChannelCover(mainVideoId), renderItem: _this.renderPlayerOverlayItem }));
        };
        _this.handleSlideToPrev = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber - 1);
        };
        _this.handleSlideToNext = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber + 1);
        };
        _this.selectNextVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, lazyLoadedVideoIds = _a.lazyLoadedVideoIds, currentIdIndex = _a.currentIdIndex;
            selectVideo(lazyLoadedVideoIds[currentIdIndex + 1]);
        };
        _this.selectPrevVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, lazyLoadedVideoIds = _a.lazyLoadedVideoIds, currentIdIndex = _a.currentIdIndex;
            selectVideo(lazyLoadedVideoIds[currentIdIndex - 1]);
        };
        _this.setCurrentVideoFromPayment = function (paymentEvent) {
            if (paymentEvent.itemId) {
                _this.props.selectVideo(paymentEvent.itemId);
            }
        };
        _this.handleActionBarContainerClick = function () {
            _this.hideAllVideos();
            _this.hideShareOverlay();
        };
        _this.handleShareRequest = function (clickEvent) {
            clickEvent.stopPropagation();
            _this.toggleShareOverlay();
        };
        _this.playVideo = function (_a) {
            var id = _a.id;
            var _b = _this.props, isPlayInFrame = _b.isPlayInFrame, channelData = _b.channelData, openFullScreenVideoOverlay = _b.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _b.closeFullScreenVideoOverlay, requestPlayVideo = _b.requestPlayVideo, isPortableDevice = _b.isPortableDevice;
            if (isPlayInFrame || isPortableDevice) {
                requestPlayVideo(id);
            }
            else {
                openFullScreenVideoOverlay(channelData.id, id, _this.isPlayAllowed(id), closeFullScreenVideoOverlay);
            }
        };
        _this.state = DEFAULT_STATE;
        return _this;
    }
    CompactView.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var isVideoPlaying = _a.isVideoPlaying;
        if (isVideoPlaying && !this.props.isVideoPlaying) {
            this.setState({ areAllVideosVisible: false });
        }
    };
    CompactView.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _this = this;
        var _a = this.props, selectedVideoId = _a.selectedVideoId, canShowVideoList = _a.canShowVideoList, canShowChannelShare = _a.canShowChannelShare;
        var _b = this.state, areAllVideosVisible = _b.areAllVideosVisible, isShareOverlayVisible = _b.isShareOverlayVisible;
        var isAllVideosVisibilityChanged = prevState.areAllVideosVisible !== areAllVideosVisible;
        var isSelectedVideoChanged = prevProps.selectedVideoId !== selectedVideoId;
        if (isSelectedVideoChanged) {
            // Because of animation for 0.3s. Needed to read updated player overlay
            // onTransitionEnd can`t be used because selected video can be updated to not rendered yet
            setTimeout(function () {
                // To read just current overlay instead of all rendered
                var visibleOverlayRef = _this.playerOverlayContainerRef &&
                    _this.playerOverlayContainerRef.querySelector('[data-is-visible-overlay="true"]');
                focus(visibleOverlayRef);
            }, 400);
        }
        var isAllVideosWasOpened = canShowVideoList && isAllVideosVisibilityChanged && areAllVideosVisible;
        if (isAllVideosWasOpened) {
            focus(this.videosContainerRef);
        }
        var isShareOverlayWasOpened = canShowChannelShare &&
            isShareOverlayVisible &&
            prevState.isShareOverlayVisible !== isShareOverlayVisible;
        if (isShareOverlayWasOpened) {
            focus(this.shareOverlayContainerRef);
        }
    };
    CompactView.prototype.getShareOverlay = function () {
        var _a = this.props, channelData = _a.channelData, mainVideo = _a.mainVideo;
        var key = mainVideo ? mainVideo.id : channelData.id;
        return (React.createElement(ShareOverlay, { key: "share-" + key, channelData: channelData, videoItem: mainVideo }));
    };
    CompactView.prototype.renderMainItemSliderNav = function () {
        var _a = this.props, hasPrevVideo = _a.hasPrevVideo, hasNextVideo = _a.hasNextVideo, isRTL = _a.isRTL;
        return (React.createElement(Nav, { prevButtonClassName: styles['nav-button-prev'], nextButtonClassName: styles['nav-button-next'], isPrevVisible: hasPrevVideo, isNextVisible: hasNextVideo, isRTL: isRTL, prevButtonAriaLabel: i18n.t('widget.accessibility.prev-video'), nextButtonAriaLabel: i18n.t('widget.accessibility.next-video'), onNextClick: this.selectNextVideo, onPrevClick: this.selectPrevVideo }));
    };
    CompactView.prototype.isVideoItemAtChannelCover = function (videoId) {
        return (this.props.isMainItemChannelCover && videoId === this.props.mainVideoId);
    };
    CompactView.prototype.isAtChannelCover = function (videoId) {
        return (this.props.canShowChannelCover && this.isVideoItemAtChannelCover(videoId));
    };
    Object.defineProperty(CompactView.prototype, "player", {
        get: function () {
            var _a = this.props, mainVideo = _a.mainVideo, canShowAutoPlay = _a.canShowAutoPlay;
            if (!mainVideo) {
                return null;
            }
            var _b = this.playerSize, width = _b.width, height = _b.height;
            var _c = this.props, channelData = _c.channelData, isPlayInFrame = _c.isPlayInFrame, isVideoPlaying = _c.isVideoPlaying, currentSiteUser = _c.currentSiteUser, PlayerComponent = _c.PlayerComponent, isPortableDevice = _c.isPortableDevice;
            var isPlayAllowed = canPlayFullOrPreview(channelData, mainVideo, currentSiteUser);
            if (!isPlayAllowed || !PlayerComponent) {
                return null;
            }
            var playerProps = {
                videoItem: mainVideo,
                channelData: channelData,
                width: width,
                height: height,
                paused: !isVideoPlaying,
                showPoster: false,
            };
            var callbackProps = {
                channelId: channelData.id,
                videoId: mainVideo.id,
            };
            if (isPortableDevice) {
                return (React.createElement(PlayerBlocksVisibility, null, function (_a) {
                    var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                    return (React.createElement(PlayerActionCallbacks, __assign({}, callbackProps),
                        React.createElement(PlayerComponent, __assign({}, playerProps, { canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton }), canShowAutoPlay && React.createElement(AutoPlayVideo, null))));
                }));
            }
            if (isPlayInFrame) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(PlayerBlocksVisibility, null, function (_a) {
                        var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                        return (React.createElement(PlayerActionCallbacks, __assign({}, callbackProps),
                            React.createElement(PlayerComponent, __assign({}, playerProps, { canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton }))));
                    }),
                    React.createElement(AutoPlayVideo, null)));
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "playerContentSize", {
        get: function () {
            var canShowVideoList = this.props.canShowVideoList;
            if (!canShowVideoList) {
                return this.playerSize;
            }
            var _a = this.playerSize, width = _a.width, height = _a.height;
            return {
                width: width,
                height: height - (this.actionBarHeight - ACTION_BAR_CLOSED_HEIGHT),
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "playerSize", {
        get: function () {
            var windowSize = this.props.windowSize;
            var width = Math.min(Math.max(windowSize.width, MIN_WIDGET_WIDTH), MAX_WIDGET_WIDTH);
            return playerSize(width, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "actionBarHeight", {
        get: function () {
            return this.areActionsVisible
                ? ACTION_BAR_OPENED_HEIGHT
                : ACTION_BAR_CLOSED_HEIGHT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "thumbnailSize", {
        get: function () {
            var _a = this.playerSize, width = _a.width, height = _a.height;
            return {
                width: width / COMPACT_VIDEOS_IN_ROW,
                height: (height - (ACTION_BAR_OPENED_HEIGHT - ACTION_BAR_CLOSED_HEIGHT)) /
                    COMPACT_NUMBER_OF_ROWS,
            };
        },
        enumerable: true,
        configurable: true
    });
    CompactView.prototype.renderVideos = function () {
        var _a = this.props, channelData = _a.channelData, videoIdsByPageNumber = _a.videoIdsByPageNumber, currentVideosPageNumber = _a.currentVideosPageNumber, canShowVideoList = _a.canShowVideoList, isVideosFetching = _a.isVideosFetching, isRTL = _a.isRTL, mainVideo = _a.mainVideo;
        var OverlayActionsCallbacks = this.overlayActionsCallbacks;
        if (!canShowVideoList || !mainVideo) {
            return null;
        }
        return (React.createElement(AccessibleVideosContainer, { className: styles['videos-container'], channelTitle: channelData.title, isChannelHasVideos: Boolean(channelData.videosCount), isVideosFetching: isVideosFetching, videoIdsByPageNumber: videoIdsByPageNumber, currentVideosPageNumber: currentVideosPageNumber, style: { height: this.videosListHeight }, onRef: this.saveVideosContainerRef },
            React.createElement(Videos, { containerWidth: this.playerContentSize.width, channelData: channelData, isVisible: this.isVideoListVisible, isRTL: isRTL, videoIdsByPageNumber: videoIdsByPageNumber, currentVideosPageNumber: currentVideosPageNumber, onSlideToPrev: this.handleSlideToPrev, onSlideToNext: this.handleSlideToNext, onBeforeClick: this.hideAllVideos, onBeforePlayRequested: this.hideAllVideos, onPlayRequestedBi: this.sendWidgetVidClickedBi, OverlayActionsCallbacks: OverlayActionsCallbacks, thumbnailSize: this.thumbnailSize })));
    };
    Object.defineProperty(CompactView.prototype, "actionBar", {
        get: function () {
            var isActionBarVisible = this.props.isActionBarVisible;
            if (!isActionBarVisible) {
                return null;
            }
            return (React.createElement(ActionBar, { height: this.actionBarHeight, areActionsVisible: this.areActionsVisible, onActionsContainerClick: this.handleActionBarContainerClick, onMenuClick: this.showAllVideos, onButtonsClick: this.hideShareOverlay }, this.actions));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "actions", {
        get: function () {
            var _this = this;
            var _a = this.props, channelData = _a.channelData, canShowVideoList = _a.canShowVideoList, canShowSignIn = _a.canShowSignIn, canShowChannelShare = _a.canShowChannelShare, canShowChannelInfo = _a.canShowChannelInfo, canShowSubscriptionButton = _a.canShowSubscriptionButton, mainVideo = _a.mainVideo;
            return (
            // TODO onUserLogin, onUserLogOut - etc. make BaseAccountButton dumb to only
            // call callbacks provided to it aka onUserLoginRequested...
            React.createElement(ChannelActionsContainer, { channelId: channelData.id }, function (channelActions) { return (React.createElement(ChannelActions, { channelData: channelData, videoItem: mainVideo, canShowSignIn: canShowSignIn, canShowChannelShare: canShowChannelShare, canShowChannelInfo: canShowChannelInfo, canShowCloseButton: canShowVideoList, canShowSubscriptionButton: canShowSubscriptionButton, onShareRequest: _this.handleShareRequest, onCloseRequest: _this.hideAllVideos, onLogInRequest: channelActions.logIn, onLogOutRequest: channelActions.logOut, onInfoRequest: channelActions.showInfo, onSubscriptionRequest: channelActions.subscribe, onSubscriptionCancelRequest: channelActions.cancelSubscription, onAccountInfoAtOverlayRequest: channelActions.showAccountInfo })); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "isVideoListVisible", {
        get: function () {
            var areAllVideosVisible = this.state.areAllVideosVisible;
            var _a = this.props, isThumbnailsPreviewHover = _a.isThumbnailsPreviewHover, canShowVideoList = _a.canShowVideoList;
            if (!canShowVideoList) {
                return false;
            }
            return areAllVideosVisible || isThumbnailsPreviewHover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "areActionsVisible", {
        get: function () {
            var canShowVideoList = this.props.canShowVideoList;
            return !canShowVideoList || this.isVideoListVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompactView.prototype, "overlayActionsCallbacks", {
        get: function () {
            var _a = this.props, isPlayInFrame = _a.isPlayInFrame, isPortableDevice = _a.isPortableDevice;
            if (isPortableDevice) {
                return InFrameMobileActionCallbacks;
            }
            if (isPlayInFrame) {
                return InFrameActionCallbacks;
            }
            return InOverlayActionCallbacks;
        },
        enumerable: true,
        configurable: true
    });
    CompactView.prototype.isPlayAllowed = function (id) {
        var _a = this.props, channelData = _a.channelData, videoByIds = _a.videoByIds, currentSiteUser = _a.currentSiteUser;
        return canPlayFullOrPreview(channelData, videoByIds[id], currentSiteUser);
    };
    CompactView.prototype.render = function () {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, isVideoPaused = _a.isVideoPaused, channelData = _a.channelData, selectedVideoId = _a.selectedVideoId, isAllCardsOverlayVisible = _a.isAllCardsOverlayVisible, isPortableDevice = _a.isPortableDevice;
        var isMainItemVisible = !isVideoPlaying &&
            !isAllCardsOverlayVisible &&
            (isPortableDevice || !isVideoPaused);
        return (React.createElement(ResizeWidgetByContent, null,
            React.createElement("main", { className: styles.container, "data-hook": "widget-container", "data-channel-layout": "compact", "aria-label": i18n.t('widget.accessibility.channel-videos-widget', {
                    channelTitle: channelData.title,
                }), tabIndex: "0" },
                React.createElement("div", { className: styles.content, style: this.playerContentSize },
                    React.createElement(Animate, { type: "fade-in", dataHook: "video-list", className: styles['videos-in-animation'], when: this.isVideoListVisible }, this.renderVideos()),
                    React.createElement(Animate, { type: "fade-in", dataHook: "player-content", when: !this.isVideoListVisible },
                        this.player,
                        isMainItemVisible && this.renderMainItemSlider(),
                        isMainItemVisible &&
                            !this.isVideoListVisible &&
                            this.renderMainItemSliderNav(),
                        React.createElement(DeeplinkPopups, null)),
                    this.getShareOverlay()),
                this.actionBar,
                React.createElement(PaymentEvents, { onRent: this.setCurrentVideoFromPayment, onSale: this.setCurrentVideoFromPayment, onSubscription: this.reset, onSubscriptionCancel: this.reset }),
                React.createElement(LiveStartHandler, { playVideo: this.playVideo, isVideoPlaying: isVideoPlaying, selectedVideoId: selectedVideoId }),
                React.createElement(WidgetPerformanceLoggers, null))));
    };
    CompactView.displayName = 'CompactView';
    CompactView.propTypes = {
        currentSiteUser: PropTypes.object,
        mainVideoId: PropTypes.string,
        mainVideo: PropTypes.object,
        isVideosFetching: PropTypes.bool,
        playVideo: PropTypes.func.isRequired,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        videoIdsByPageNumber: PropTypes.array,
        windowSize: PropTypes.object.isRequired,
        currentIdIndex: PropTypes.number,
        hasNextVideo: PropTypes.bool.isRequired,
        hasPrevVideo: PropTypes.bool.isRequired,
        isActionBarVisible: PropTypes.bool,
        canShowAutoPlay: PropTypes.bool,
        canShowChannelShare: PropTypes.bool,
        canShowChannelInfo: PropTypes.bool,
        canShowVideoList: PropTypes.bool,
        canShowSignIn: PropTypes.bool,
        isThumbnailsPreviewHover: PropTypes.bool,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        resetPlaybackStatus: PropTypes.func,
        resetSelectedVideo: PropTypes.func,
        reportStats: PropTypes.func,
        isSingleVideo: PropTypes.bool,
        setSavedTime: PropTypes.func,
        goToLazyVideosPageIndex: PropTypes.func.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        selectedVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool.isRequired,
        lazyLoadedVideoIds: PropTypes.array.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        canShowSubscriptionButton: PropTypes.bool.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        closeShareOverlay: PropTypes.func.isRequired,
        isVideoPaused: PropTypes.bool.isRequired,
        isAllCardsOverlayVisible: PropTypes.bool.isRequired,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
        isRTL: PropTypes.bool,
    };
    CompactView = __decorate([
        connect(function (state) { return ({
            windowSize: state.windowSize,
            videoIdsByPageNumber: getVideoIdsByPageNumber(state),
            currentVideosPageNumber: getCurrentPageIndex(state),
            lazyLoadedVideoIds: getVideoIds(state),
            isVideoPaused: isVideoPausedOptimistic(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
            isPlayInFrame: isPlayInFrame(state),
            mainVideoId: getMainVideoId(state),
            mainVideo: getMainVideo(state),
            selectedVideoId: state.selectedVideoId,
            hasNextVideo: hasNextVideo(state),
            hasPrevVideo: hasPrevVideo(state),
            currentIdIndex: getCurrentVideoIndex(state),
            videoByIds: getVideosGroupedByIds(state),
            isVideosFetching: getIsFetching(state),
            currentSiteUser: getCurrentSiteUser(state),
            canShowAutoPlay: showAutoPlay(state),
            canShowChannelCover: !state.isVideosTouched,
            canShowChannelInfo: getCanShowChannelInfo(state),
            canShowChannelShare: canShowChannelShare(state),
            canShowVideoList: canShowVideoList(state),
            canShowSignIn: getCanShowSignIn(state),
            canShowSubscriptionButton: isSubscriptionButtonVisible({
                channel: getChannelForWidget(state),
            }),
            isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
            isActionBarVisible: canShowActionBar(state),
            isAllCardsOverlayVisible: isAllCardsOverlayVisible(state),
            isRTL: isRTL(state),
            channelData: getChannelForWidget(state),
            isSingleVideo: isSingleVideo(state),
            isMainItemChannelCover: isMainItemChannelCover(state),
        }); }, {
            selectVideo: selectVideo,
            playVideo: playVideo,
            pauseVideo: pauseVideo,
            requestPlayVideo: requestPlayVideo,
            resetSelectedVideo: resetSelectedVideo,
            resetPlaybackStatus: resetPlaybackStatus,
            reportStats: reportStats,
            closeShareOverlay: closeShareOverlay,
            setSavedTime: setSavedTime,
            goToLazyVideosPageIndex: goToLazyVideosPageIndex,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            logWidgetSystem: logWidgetSystem,
            logWidgetVidClick: logWidgetVidClick,
            sendBiEvent: sendBiEvent,
        }),
        withStyles(styles)
    ], CompactView);
    return CompactView;
}(React.Component));
export { CompactView };
export default withPlayerModuleLoader(CompactView);
