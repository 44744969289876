import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PlayIcon from '../../../../../../static/images/play.svg';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayPlayButton = /** @class */ (function (_super) {
    __extends(OverlayPlayButton, _super);
    function OverlayPlayButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (event) {
            event.stopPropagation();
            event.preventDefault();
            _this.props.onClick();
        };
        return _this;
    }
    OverlayPlayButton.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, isIconOnly = _b.isIconOnly, isFocusable = _b.isFocusable, className = _b.className, iconClassName = _b.iconClassName, ariaHidden = _b.ariaHidden;
        var classNames = classnames(styles.button, className, (_a = {},
            _a[styles['only-icon']] = isIconOnly,
            _a));
        return (React.createElement("div", { className: classNames, onClick: this.handleClick, "data-hook": "overlay-play-button", "aria-hidden": ariaHidden, tabIndex: isFocusable ? 0 : -1 },
            React.createElement(PlayIcon, { className: classnames(styles.icon, iconClassName) }),
            children && React.createElement("span", { className: styles.text }, children)));
    };
    OverlayPlayButton.propTypes = {
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node,
        isFocusable: PropTypes.bool,
        isIconOnly: PropTypes.bool,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        ariaHidden: PropTypes.bool,
    };
    OverlayPlayButton.defaultProps = {
        isFocusable: true,
        isIconOnly: false,
        ariaHidden: false,
    };
    OverlayPlayButton = __decorate([
        withStyles(styles)
    ], OverlayPlayButton);
    return OverlayPlayButton;
}(React.Component));
export default OverlayPlayButton;
